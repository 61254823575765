import React, {
    useState, useContext, useMemo, useEffect
} from 'react';
import getStyles from '../../project/styles/widget-styles/lang-switcher-styles';
import Styles from '../../common/decorators/Styles';
import UserContext from '../../common/utils/getContext';
import { Div, P } from '../../hybrid/wrappers';
import T from '../../common/components/T';
import Modal from '../regionAndCurrencySwitcher/regionAndCurrencySwitcherModal';
import { getWidgetTranslates } from '../../common/utils/utils';
import { LangContext } from '../../common/decorators/Language';
import ReactCountryFlag from 'react-country-flag';
import { getData } from '../../common/utils/router';
import { useRouter } from 'next/router';

const LangSwitcher = ({ page, styles }) => {
    const [showLangs, setShowLangs] = useState(false);
    const [isRedirecting, setIsRedirecting] = useState(false);

    const { translation } = useContext(LangContext) || {};

    const {
        localization,
        lang,
        activeLangs,
        userToken,
        setState
    } = useContext(UserContext);

    const router = useRouter()

    const langsForMenu = useMemo(() => activeLangs?.filter((l) => l.active && l.show), [activeLangs])

    const activeLanguage = useMemo(() => activeLangs && activeLangs?.find((language) => language.alias === lang) || activeLangs?.find((language) => language.isDefault) || null, [activeLangs, lang])

    const [trans, setTranslates] = useState(null);
    useEffect(() => {
        getWidgetTranslates(lang, 'SiteSettings', translation, setTranslates);
    }, [lang, translation])

    if (!activeLanguage || !langsForMenu.length) return null;

    const redirectToDomain = async (newLocalization) => {
        setIsRedirecting(true)
        const shopsData = await getData('/api/v1/shop/shop', userToken, lang);

        const findShop = shopsData?.data?.find(el => el.country.geoname_id === newLocalization.country.geoname_id || el.country.geoname_id === newLocalization.country.id)
        const { pathname, searchParams } = new URL(window.location.href?.includes('store') ? `${process.env.NEXT_PUBLIC_SERVER_FRONT_URL}/${lang}/store`: window.location.href)
        searchParams.delete('hash')
        searchParams.delete('kzState')
        searchParams.delete('country_id')
        searchParams.delete('country')
        if (findShop && findShop?.domain?.name && findShop.status !== 'hidden'){
            const hashData = await getData('/api/v1/get-hash', userToken, lang);
            if (hashData.hash){
                searchParams.set('hash', hashData?.hash);
            }
            searchParams.toString()

            const langPrefixRegex = new RegExp(`^\\/${lang}\\/`);

            if (router.asPath.length > 2) {
                window.location.href = `${findShop.domain.name}${pathname}${!!searchParams.size ? `?${searchParams}` : ''}`?.replace(langPrefixRegex, `/${findShop.locale}/`)
            } else {
                window.location.href = `${findShop.domain.name}${pathname}${findShop.locale}${!!searchParams.size ? `?${searchParams}` : ''}`;
            }
        } else if (process.env.NEXT_PUBLIC_SERVER_FRONT_URL !== process.env.NEXT_PUBLIC_MAIN_SERVER_FRONT_URL && newLocalization.country.code == 'KZ'){
            const hashData = await getData('/api/v1/get-hash', userToken, lang);
            if (hashData.hash){
                searchParams.set('hash', hashData?.hash);
            }
            searchParams.set('kzState', 'true')
            searchParams.toString()
            window.location.href = `${process.env.NEXT_PUBLIC_MAIN_SERVER_FRONT_URL}${pathname === '/'? `/${newLocalization?.language? newLocalization?.language: lang ? lang : ''}`: pathname}${!!searchParams.size ? `?${searchParams}` : ''}`
        } else if (process.env.NEXT_PUBLIC_SERVER_FRONT_URL !== process.env.NEXT_PUBLIC_MAIN_SERVER_FRONT_URL){
            const hashData = await getData('/api/v1/get-hash', userToken, lang);
            if (hashData.hash){
                searchParams.set('hash', hashData?.hash);
            }
            searchParams.set('country', newLocalization.country.code)
            searchParams.set('country_id', newLocalization.country.id)
            searchParams.toString()
            window.location.href = `${process.env.NEXT_PUBLIC_MAIN_SERVER_FRONT_URL}${pathname === '/'? `/${newLocalization?.language? newLocalization?.language: lang ? lang : ''}`: pathname}${!!searchParams.size ? `?${searchParams}` : ''}`
        } else {
            setState('localization', newLocalization)
        }
        setIsRedirecting(false)
    }


    return (
        <>
            {
                isRedirecting && <Div styles={styles.loaderWrapper}>
                    <svg className='spinnerCart' viewBox='0 0 50 50'>
                        <circle className='path' cx='25' cy='25' r='20' fill='none' strokeWidth='5'></circle>
                    </svg>
                </Div>
            }
            <Div
                styles={styles.mobileMenuLangSwitcher}
                data-uitest='642_uitest' onClick={() => {
                    setShowLangs(true)
                }}
            >
                <P styles={styles.mobileMenuLangSwitcherLangFlag}>
                    {
                        activeLanguage && localization?.country?.code ?
                            <ReactCountryFlag svg countryCode={localization?.country?.code} style={{
                                objectFit: 'cover', width: '100%', height: '100%'
                            }} /> : null
                    }
                </P>
                <Div styles={styles.mobileMenuLangSwitcherInfo}>
                    <P styles={styles.mobileMenuLangSwitcherTitle}>
                        <T customTranslates={trans} textName='SiteSettings-app-setting' defaultStr='Настройки сайта' page='widget'
                            group='SiteSettings'/>
                    </P>
                    <P styles={styles.mobileMenuLangSwitcherName}>
                        {activeLanguage?.name}{activeLanguage?.name ? ', ' : ''}{localization.currency}
                    </P>
                </Div>
            </Div>
            {
                showLangs &&
                <Modal
                    redirectToDomain={redirectToDomain}
                    mobile={true}
                    setIsShowPortalModal={setShowLangs}
                    page={page}
                />

            }
        </>
    )
};
export default Styles(LangSwitcher, getStyles);